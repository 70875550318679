import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Row, Col } from 'reactstrap'

import ArticleAboutModal from './article-about-modal'
import {
  ArticleWrapper,
  ArticleContainer,
  ArticlePersonWrapper,
  ArticlePersonPhotoColWrapper,
  ArticlePersonPhotoWrapper,
  ArticlePersonAboutColWrapper,
  ArticlePersonDescColWrapper,
  ArticlePersonDescWrapper,
} from '../elements'

const Article = (props) => {
  const data = useStaticQuery(graphql`
    query {
      ArticlePerson1: file(
        relativePath: { eq: "parent/article5/person5.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image1: file(relativePath: { eq: "parent/article5/image1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image2: file(relativePath: { eq: "parent/article5/image2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image3: file(relativePath: { eq: "parent/article5/image3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 475) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      Article1Image4: file(relativePath: { eq: "parent/article5/image4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 610) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <ArticleWrapper>
      <ArticleContainer>
        <ArticlePersonWrapper>
          <Row>
            <ArticlePersonPhotoColWrapper>
              <ArticlePersonPhotoWrapper>
                <Img
                  alt="Tomasz Jaroszek - TBC"
                  fluid={data.ArticlePerson1.childImageSharp.fluid}
                  className="articlePersonImage"
                />
              </ArticlePersonPhotoWrapper>
            </ArticlePersonPhotoColWrapper>
          </Row>
          <Row>
            <ArticlePersonAboutColWrapper>
              <div className="personInfo">
                <span className="personName">Tomasz Jaroszek</span>{' '}
                {/*<span className="personPosition">TBC</span>*/}
                <br />
                <ArticleAboutModal>
                  Autor bloga Doradca.tv i{'\u00A0'}podcastu Nic Za Darmo, były
                  dziennikarz ekonomiczny. Z{'\u00A0'}rynkiem finansowym
                  związany od
                  {'\u00A0'}ponad 13 lat, zajmuje się edukacją finansową.
                  Współautor książek &bdquo;Śladami Warrena Buffetta&rdquo; oraz
                  &bdquo;Ryzykować trzeba umieć&rdquo;.
                </ArticleAboutModal>
              </div>
            </ArticlePersonAboutColWrapper>
            <ArticlePersonDescColWrapper>
              <p>
                Dziecko uczy się cały czas, a{'\u00A0'}nie tylko wtedy, gdy
                chcieliby tego rodzice czy{'\u00A0'}nauczyciele. Młodzi ludzie
                obserwują otoczenie, rodziców, kolegów i{'\u00A0'}koleżanki,
                chłonąc jak gąbka zarówno to, co{'\u00A0'}ludzie mówią, jak i
                {'\u00A0'}to, jak się zachowują. To{'\u00A0'}właśnie dlatego ze
                {'\u00A0'}złymi stereotypami na{'\u00A0'}temat pieniędzy trzeba
                się szybko rozprawić.
                <br />
                Oto 7{'\u00A0'}często powtarzanych tez na temat pieniędzy, które
                nijak mają się do{'\u00A0'}rzeczywistości i{'\u00A0'}mogą mieć
                na nas negatywny wpływ:
              </p>
            </ArticlePersonDescColWrapper>
          </Row>
        </ArticlePersonWrapper>
        <ArticlePersonDescWrapper>
          <Row className="marginTop30">
            <Col xs={12} lg={6}>
              <h2 className="noMarginTop">
                1. Pieniądze to{'\u00A0'}nie temat dla dzieci
              </h2>
              <p>
                Żeby nauczyć się zarządzać domowym budżetem, oszczędzać czy w
                {'\u00A0'}ogóle zainteresować się tematem własnych pieniędzy,
                trzeba o{'\u00A0'}nich rozmawiać. Niestety od{'\u00A0'}lat
                panuje przekonanie, że{'\u00A0'}pieniądze to{'\u00A0'}temat
                wyłącznie dla dorosłych. Ciągłe pomijanie dziecka w{'\u00A0'}
                takich rozmowach powoduje, że{'\u00A0'}automatycznie przestaje
                się ono tematem interesować w{'\u00A0'}czasie bardzo
                intensywnego rozwoju intelektualnego. To{'\u00A0'}dosyć
                paradoksalne, ale rodzice wymagają od{'\u00A0'}dziecka, aby w
                {'\u00A0'}wieku szkolnym uczyło się fizyki, chemii czy biologii,
                a{'\u00A0'}całkowicie zapominają o{'\u00A0'}edukacji finansowej,
                która w{'\u00A0'}dorosłych latach okaże się najbardziej
                potrzebną wiedzą ze{'\u00A0'}wszystkich. Warto rozmawiać z
                {'\u00A0'}dziećmi o{'\u00A0'}pieniądzach – nie tylko dużo się
                nauczą, ale też będą się czuły docenione przez rodziców.
              </p>
              <h2>2. Pierwszy milion trzeba ukraść</h2>
              <p>
                To{'\u00A0'}bardzo szkodliwy stereotyp, który od{'\u00A0'}
                najmłodszych lat może wzbudzać niechęć do{'\u00A0'}
                przedsiębiorczości, rynków finansowych czy też zamożnych ludzi.
                Takie zdanie powtarzane przez lata może zbudować w{'\u00A0'}nas
                silne przeświadczenie, że{'\u00A0'}nie da{'\u00A0'}się dobrze
                zarabiać czy też zbudować firmy samodzielnie i{'\u00A0'}
                uczciwie. Stereotyp sięgający poprzedniego ustroju miał
                dokładnie takie zadanie: zniechęcić mniej zarabiających do
                {'\u00A0'}przedsiębiorców, którzy byli niemile widziani w
                {'\u00A0'}ustroju promującym klasę robotniczą. Jeśli w{'\u00A0'}
                młodym człowieku ma{'\u00A0'}kiełkować ziarno przedsiębiorczości
                i{'\u00A0'}chęć wspinania się po{'\u00A0'}szczeblach kariery,
                trzeba pilnie pozbyć się tego stereotypu.
              </p>
            </Col>
            <Col xs={12} lg={6}>
              <Img
                fluid={data.Article1Image1.childImageSharp.fluid}
                className="articleImage"
              />
            </Col>
            <Col className="marginTop30" xs={12} sm={6}>
              <div className="imgWrapper">
                <Img
                  fluid={data.Article1Image2.childImageSharp.fluid}
                  className="articleImage"
                />
              </div>
            </Col>
            <Col className="marginTop30" xs={12} sm={6}>
              <h2 className="noMarginTop">3. Nic nie osiągniesz bez studiów</h2>
              <p>
                Każdy rodzic chce, aby jego dziecko zdobyło dobre wykształcenie
                i{'\u00A0'}później poradziło sobie w{'\u00A0'}życiu. Jednak
                powtarzanie jak mantry zdania, że{'\u00A0'}za{'\u00A0'}sukcesem
                finansowym stoi zawsze dyplom magistra, może przynieść czasem
                odwrotny skutek. Warto pokazać, że{'\u00A0'}są{'\u00A0'}różne
                drogi rozwoju, a{'\u00A0'}w{'\u00A0'}dzisiejszych realiach
                biznesowych standardem jest wiele miejsc pracy w{'\u00A0'}życiu,
                często niezwiązanych ze{'\u00A0'}zdobytym wykształceniem. Coraz
                częściej to{'\u00A0'}doświadczenie i{'\u00A0'}umiejętności
                odgrywają dużo większą rolę niż posiadany dyplom, a{'\u00A0'}w
                {'\u00A0'}kontekście przedsiębiorczości zasady są{'\u00A0'}
                jeszcze inne.
              </p>
              <h2>4. Odłożysz, jak zostanie na{'\u00A0'}koniec miesiąca</h2>
              <p>
                W{'\u00A0'}czasach gdy konsumpcjonizm jest motorem napędowym
                światowej gospodarki, a{'\u00A0'}stopy procentowe pozostają na
                {'\u00A0'}rekordowo niskich poziomach, oszczędzanie jest
                ostatnią rzeczą, którą jesteśmy zainteresowani. Poza tym bardzo
                często słyszymy „dobrą radę”, że{'\u00A0'}powinniśmy odkładać
                pieniądze dopiero, jak już zrealizujemy wszystkie wydatki – w
                {'\u00A0'}ten sposób zawsze odłożona reszta trafi do{'\u00A0'}
                oszczędności. Oczywiście to{'\u00A0'}powiedzenie kompletnie nie
                spełnia swojej roli, bo z{'\u00A0'}takim podejściem z{'\u00A0'}
                reguły zabraknie nam pieniędzy, które miałyby trafić na
                {'\u00A0'}konto oszczędnościowe.
                <br />
                Oszczędzanie również powinniśmy planować i{'\u00A0'}zawsze
                „najpierw płacić sobie”. Jeśli wpiszemy oszczędności w{'\u00A0'}
                budżet domowy, zawsze będziemy mieć pewność, że{'\u00A0'}trafią
                tam, gdzie powinny, a{'\u00A0'}dopiero reszta pieniędzy będzie
                przeznaczona na{'\u00A0'}wydatki. Skuteczne oszczędzanie to
                {'\u00A0'}proces regularny, zaplanowany i{'\u00A0'}przemyślany.
              </p>
            </Col>
            <Col className="marginTop30" x xs={12} lg={6}>
              <h2 className="noMarginTop">
                5. Dżentelmeni nie mówią o{'\u00A0'}pieniądzach
              </h2>
              <p>
                Nie dość, że{'\u00A0'}o{'\u00A0'}pieniądzach nie rozmawiamy z
                {'\u00A0'}dziećmi, to{'\u00A0'}jeszcze nie rozmawiamy o
                {'\u00A0'}nich nawet sami ze{'\u00A0'}sobą. Niestety stereotyp,
                że{'\u00A0'}nie wypada rozmawiać o{'\u00A0'}pieniądzach, niesie
                za{'\u00A0'}sobą przykre konsekwencje. Wiele przemilczanych
                tematów później kończy się{'\u00A0'}konfliktem, a{'\u00A0'}
                nieporozumienia na{'\u00A0'}tle finansowym są{'\u00A0'}w
                {'\u00A0'}Polsce wymieniane nawet jako jedna z{'\u00A0'}przyczyn
                rozwodów.
                <br />
                Poza tym jest jeszcze jeden aspekt braku otwartości w{'\u00A0'}
                rozmowie o{'\u00A0'}pieniądzach – odbija się to{'\u00A0'}
                negatywnie na{'\u00A0'}przepływie wiedzy. W{'\u00A0'}wielu
                rozwiniętych krajach zarówno przedsiębiorcy, jak i{'\u00A0'}
                pracownicy firm otwarcie mówią o{'\u00A0'}pieniądzach i
                {'\u00A0'}dzielą się wiedzą, co{'\u00A0'}naturalnie sprzyja
                przedsiębiorczości.
              </p>
              <h2>6. Giełda to{'\u00A0'}kasyno</h2>
              <p>
                Ten stereotyp jest powtarzany głównie przez osoby, które nigdy
                nie miały do{'\u00A0'}czynienia z{'\u00A0'}rynkami finansowymi.
                Oczywiście inwestowanie zawsze wiąże się z{'\u00A0'}ryzykiem,
                ale długoterminowe podejście do{'\u00A0'}giełdy i{'\u00A0'}
                regularne kupowanie czy to{'\u00A0'}funduszy pasywnych, czy
                akcji spółek dywidendowych może przynieść niesamowite efekty
                przy zachowaniu odpowiedniej regularności.
                <br />
                Warto nie tylko wyrzucić pilnie ten stereotyp z{'\u00A0'}głowy,
                ale również zacząć się uczyć, jak działają rynki finansowe.
                Prawda jest taka, że{'\u00A0'}jeszcze nigdy dostęp do światowych
                rynków nie był taki łatwy, a{'\u00A0'}dostęp do globalnych
                instrumentów to{'\u00A0'}zupełnie inne możliwości inwestycyjne,
                które przez wiele lat były dla nas albo trudno dostępne, albo
                bardzo drogie.
              </p>
            </Col>
            <Col className="marginTop30" xs={12} lg={6}>
              <Img
                fluid={data.Article1Image3.childImageSharp.fluid}
                className="articleImage"
              />
            </Col>

            <Col className="marginTop30" xs={12} lg={6}>
              <Img
                fluid={data.Article1Image4.childImageSharp.fluid}
                className="articleImage"
              />
            </Col>
            <Col className="marginTop30" xs={12} lg={6}>
              <h2 className="noMarginTop">
                7. Za{'\u00A0'}młody na{'\u00A0'}oszczędzanie{' '}
              </h2>
              <p>
                Procent składany to{'\u00A0'}genialny wynalazek, który
                potrzebuje wielu lat, aby zacząć działać i{'\u00A0'}przynosić
                bardzo dobre efekty. Każde badanie na{'\u00A0'}historycznych
                danych rynkowych jasno pokazuje, że{'\u00A0'}im{'\u00A0'}
                wcześniej zaczniemy odkładać pieniądze i{'\u00A0'}je{'\u00A0'}
                inwestować, tym lepsze efekty osiągniemy. Szkopuł w tym, że
                namówienie dwudziestolatka do{'\u00A0'}odkładania pieniędzy
                graniczy często z{'\u00A0'}cudem, a{'\u00A0'}i{'\u00A0'}
                trzydziestolatek zawsze znajdzie wymówkę i{'\u00A0'}inne ważne
                wydatki. Tym sposobem możemy stracić całe dwie dekady, podczas
                których małe kwoty budowałyby kapitał pracujący na{'\u00A0'}nas
                przez kolejne lata
              </p>
            </Col>
          </Row>
        </ArticlePersonDescWrapper>
      </ArticleContainer>
    </ArticleWrapper>
  )
}

export default Article
