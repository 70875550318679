import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import ArticleHeader from '../../components/parent/article-header'
import Article5 from '../../components/parent/article5'
import UsefulTools from '../../components/parent/useful-tools'

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      ArticleHeaderImage1: file(
        relativePath: { eq: "parent/article5/article-header5.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1110) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool1: file(relativePath: { eq: "parent/tools/gry.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Tool2: file(relativePath: { eq: "parent/tools/kolorowanka.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <SEO
        title="7 stereotypów na temat pieniędzy, z którymi warto rozprawiać się od dziecka"
        description="Świat finansów jest pełen stereotypów na temat pieniędzy, które często bezwiednie powtarzamy. Mogą zdziałać dużo złego, szczególnie jeśli ukształtują negatywnie nasz sposób myślenia już od najmłodszych lat ‒ właśnie dlatego warto uporać się z nimi już w dzieciństwie."
        linkImage={data.ArticleHeaderImage1.childImageSharp.fluid.src}
      />

      <ArticleHeader
        image={data.ArticleHeaderImage1.childImageSharp.fluid}
        date="12.04.2021"
        title={
          <>
            7{'\u00A0'}stereotypów na{'\u00A0'}temat pieniędzy,
            <br /> z{'\u00A0'}którymi warto rozprawiać się od{'\u00A0'}dziecka
          </>
        }
        desc={
          <p>
            Świat finansów jest pełen stereotypów na temat
            <br /> pieniędzy, które często bezwiednie powtarzamy. Mogą
            <br /> zdziałać dużo złego, szczególnie jeśli ukształtują
            <br /> negatywnie nasz sposób myślenia już od{'\u00A0'}najmłodszych
            <br /> lat ‒ właśnie dlatego warto uporać się z{'\u00A0'}nimi już
            <br /> w{'\u00A0'}dzieciństwie.
          </p>
        }
        linkPrev="/strefa-rodzica/baza-wiedzy"
        linkNext="/strefa-rodzica/baza-wiedzy#narzedzia"
      />
      <Article5 />
      <UsefulTools
        items={[
          {
            title: 'Gry',
            subtitle: 'Budżet Samodzielniaka / Plansza i instrukcja',
            image: data.Tool1,
            link: '/parent/Budzet_Samodzielniaka_plansza_instrukcja.pdf',
          },
          {
            title: 'Kolorowanki i rebusy',
            subtitle: 'Kolorowanka',
            image: data.Tool2,
            link: '/parent/Kolorowanka%201.pdf',
          },
          {
            title: 'Kolorowanki i rebusy',
            subtitle: 'Rebusy',
            image: data.Tool2,
            link: '/parent/Rebusy.pdf',
          },
        ]}
      />
    </Layout>
  )
}

export default IndexPage
